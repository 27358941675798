<template>
  <div>
    <div class="banner">
      <warning-tip code="SPTSSPWD"></warning-tip>

      <main-top
        title="问大家"
        desc="在线查询天猫、淘宝商品宝贝问大家"
        spend="SPTSSPWD"
      >
        <!-- 使用搜索框组件 -->
        <InputView
            :extractProductId="true"
            :CanYouClick="CanYouClick"
            placeholder-name="请输入您的宝贝链接或宝贝ID"
            @sendSearchName="getSearchName"
          ></InputView>
      </main-top>

      <bordure-row title="商品信息" shadow style="margin-bottom: 30px">
        <div class="bannerContent" style="padding-bottom: 20px">
          <div class="img_floor" v-if="goodsEntity[0].itemPic == ''"></div>
          <img
            v-else
            :src="goodsEntity[0].itemPic"
            style="dispaly: block; width: 100px; height: 100px"
            alt="图片加载中"
          />
          <div style="margin-left: 16px">
            <p
              style="
                font-size: 14px;
                color: #333333;
                letter-spacing: 0;
                margin: 6px 0;
              "
            >
              商品标题：{{ goodsEntity[0].itemTitle }}
            </p>
            <p
              style="
                font-size: 12px;
                color: #666666;
                letter-spacing: 0;
                margin-bottom: 16px;
              "
            >
              问答关键字：{{ goodsEntity[0].questionWords }}
            </p>
            <p style="font-size: 14px; color: #333333; letter-spacing: 0">
              购买人数：{{ goodsEntity[0].buyerCount }}
            </p>
          </div>
        </div>
        <!-- 表格信息 -->
        <div>
          <div class="control-tool">
            <el-button type="primary" size="small" @click="downloadExcel"
              >一键导出</el-button
            >
          </div>
          <el-table
            :data="dataList"
            style="width: 100%; border: 1px solid #f4f4f4"
            size="mini"
            :header-cell-style="{
              background: '#f8f8f8',
              'font-family': 'PingFangSC-Regular',
              color: '#666666 !important',
              'letter-spacing': '0',
              'text-align': 'center',
              'font-weight': 'normal',
            }"
            row-key="clusterId"
          >
            <el-table-column
              align="center"
              prop="gmtCreate"
              label="创建时间"
              sortable
              min-width="100"
              :formatter="dateFormat"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="subFeeds[0].user.userNick"
              label="买家昵称"
              min-width="100"
            ></el-table-column>
            <el-table-column
              prop="title"
              label="问题"
              width="220"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="subFeeds[0].gmtCreate"
              label="回答时间"
              :formatter="answerFormat"
              min-width="100"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="subFeeds[0].title"
              label="回答"
              width="220"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="targetUrl"
              align="center"
              label="链接"
              min-width="240"
            >
              <template slot-scope="scope">
                <a
                  :href="scope.row.targetUrl"
                  target="_blank"
                  class="target-link"
                  >{{ scope.row.targetUrl }}</a
                >
              </template>
            </el-table-column>
          </el-table>
          <!-- 以下部分为分页器 -->
          <el-pagination
              class="pagination"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-size="10"
              layout="total, prev, pager, next,jumper"
              :total="total"
            >
            </el-pagination>
        </div>
      </bordure-row>

      <!-- 讲解文案区域 -->
      <Introduce :introduce="introduce"></Introduce>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";

import { getAskEveryone } from "../../request/api"; //这里是引入请求
import InputView from "../public/InputView";
import Introduce from "../public/Introduce";
export default {
  data() {
    return {
      CanYouClick: true,
      currentPage: 1, //当前第几页
      total: 10, //表格总条数
      value: "",
      goodsEntity: [
        {
          questionWords:
            "[起球,质量,纯棉,粘毛,偏小,掉色,缩水,身高,变形,体重]  (模拟数据)",
          questionCount: "64",
          itemTitle:
            "早春上新纯棉白色长袖t恤女秋冬洋气打底衫女半高领百搭哦上衣女  (模拟数据)",
          itemPic:
            "https://gw.alicdn.com/tfscom/tuitui/i3/2176361863/O1CN01dsvA2O1PdIWwvPu1v_!!0-item_pic.jpg",
          buyerCount: "2949  (模拟数据)",
        },
      ],
      dataList: [],
      introduce: [
        {
          title: "有什么作用？",
          icon: "&#xe60a;",
          content: ["方便商家收集买家最关心的问题，针对问题来做好自己的产品"],
        },
      ], //介绍相关数组

      excelData: [],
    };
  },
  created() {
    (this.dataList = [
      {
        gmtModified: "1608269322000",
        cardType: "1",
        clusterId: "290832745509",
        gmtCreate: "1607776344000",
        title: "够厚吗，穿了这件里面还能穿得进去保暖内衣吗  (模拟数据)",
        subFeedsCount: "6",
        subFeeds: [
          {
            gmtModified: null,
            elements: [],
            feedType: "151",
            answerType: "1",
            id: "291399515589",
            gmtCreate: "1607783131000",
            title: "春秋天可以，我是搭在卫衣里面穿的  (模拟数据)",
            user: {
              userNick: "会**员  (模拟数据)",
              userScore: null,
              userLogo:
                "https://wwc.alicdn.com/avatar/getAvatar.do?type=sns&width=64&height=64&userIdStr=Pm8SvCQ4MC7zOmgLXF8yXFNLv8ZHM07Hv8k4PCZHM0gT",
              userUrl:
                "https://h5.m.taobao.com/account/index.html?userId=Pm8SvCQ4MC7zOmgLXF8yXFNLv8ZHM07Hv8k4PCZHM0gT",
            },
          },
        ],
        similarFeeds: null,
        clusterName: null,
        feedType: "200",
        id: "290832745509",
        similarCount: null,
        targetUrl:
          "https://market.m.taobao.com/app/mtb/questions-and-answers/pages/detail/index.html?from=answer&spm=a3134.14087530&id=290832745509&clusterId=290832745509  (模拟数据)",
      },
      {
        gmtModified: "1603789220000",
        cardType: "1",
        clusterId: "284207829396",
        gmtCreate: "1603638929000",
        title: "外面是棉的还是摸着光滑的那种吖  (模拟数据)",
        subFeedsCount: "23",
        subFeeds: [
          {
            gmtModified: null,
            elements: [],
            feedType: "151",
            answerType: "1",
            id: "284547182487",
            gmtCreate: "1603702185000",
            title: "棉的，不是光滑的化纤面料  (模拟数据)",
            user: {
              userNick: "用**6  (模拟数据)",
              userScore: null,
              userLogo:
                "https://wwc.alicdn.com/avatar/getAvatar.do?type=sns&width=64&height=64&userIdStr=P8P-XFxWO8x0vG8yvCRHvmQWPmvWPG9IO8cSOFZhOF8T",
              userUrl:
                "https://h5.m.taobao.com/account/index.html?userId=P8P-XFxWO8x0vG8yvCRHvmQWPmvWPG9IO8cSOFZhOF8T",
            },
          },
        ],
        similarFeeds: null,
        clusterName: null,
        feedType: "200",
        id: "284207829396",
        similarCount: null,
        targetUrl:
          "https://market.m.taobao.com/app/mtb/questions-and-answers/pages/detail/index.html?from=answer&spm=a3134.14087530&id=284207829396&clusterId=284207829396  (模拟数据)",
      },
      {
        gmtModified: "1610598921000",
        cardType: "1",
        clusterId: "294360840676",
        gmtCreate: "1610357945000",
        title: "黑色粘毛么  (模拟数据)",
        subFeedsCount: "8",
        subFeeds: [
          {
            gmtModified: null,
            elements: [
              {
                path: "https://gw2.alicdn.com/tfscom/tuitui/O1CN01SGJyOW2GB3jvpGgVH_!!2900888976-0-taobaogroupaskmember.jpg",
                width: "744",
                id: "1897607031469892235",
                type: "pic",
                picId: "1897607031469892235",
                height: "992",
              },
            ],
            feedType: "151",
            answerType: "1",
            id: "294427000849",
            gmtCreate: "1610408456000",
            title: "不会粘毛吧，我买的是黄色的，质量还是不错的。  (模拟数据)",
            user: {
              userNick: "张**勤  (模拟数据)",
              userScore: null,
              userLogo:
                "https://wwc.alicdn.com/avatar/getAvatar.do?type=sns&width=64&height=64&userIdStr=PGcSOFcyv8ZIMC7zMkQSPmN0PFM-OFRIMC-IPGNWMFHT",
              userUrl:
                "https://h5.m.taobao.com/account/index.html?userId=PGcSOFcyv8ZIMC7zMkQSPmN0PFM-OFRIMC-IPGNWMFHT",
            },
          },
        ],
        similarFeeds: null,
        clusterName: null,
        feedType: "200",
        id: "294360840676",
        similarCount: null,
        targetUrl:
          "https://market.m.taobao.com/app/mtb/questions-and-answers/pages/detail/index.html?from=answer&spm=a3134.14087530&id=294360840676&clusterId=294360840676  (模拟数据)",
      },
      {
        gmtModified: "1613786900000",
        cardType: "1",
        clusterId: "298292304064",
        gmtCreate: "1613779675000",
        title: "缩水吗？  (模拟数据)",
        subFeedsCount: "2",
        subFeeds: [
          {
            gmtModified: null,
            elements: [],
            feedType: "151",
            answerType: "1",
            id: "298921322401",
            gmtCreate: "1613783292000",
            title: "不  (模拟数据)",
            user: {
              userNick: "0**o  (模拟数据)",
              userScore: null,
              userLogo:
                "https://wwc.alicdn.com/avatar/getAvatar.do?type=sns&width=64&height=64&userIdStr=M8l-PCRIMC*-MG*zvFH0PHcLvGvSPF7eXmQ4XH7-XFIT",
              userUrl:
                "https://h5.m.taobao.com/account/index.html?userId=M8l-PCRIMC*-MG*zvFH0PHcLvGvSPF7eXmQ4XH7-XFIT",
            },
          },
        ],
        similarFeeds: null,
        clusterName: null,
        feedType: "200",
        id: "298292304064",
        similarCount: null,
        targetUrl:
          "https://market.m.taobao.com/app/mtb/questions-and-answers/pages/detail/index.html?from=answer&spm=a3134.14087530&id=298292304064&clusterId=298292304064  (模拟数据)",
      },
      {
        gmtModified: "1608308393000",
        cardType: "1",
        clusterId: "292021847719",
        gmtCreate: "1608209987000",
        title: "修身款吗？起球吗？是加绒的吗？  (模拟数据)",
        subFeedsCount: "9",
        subFeeds: [
          {
            gmtModified: null,
            elements: [],
            feedType: "151",
            answerType: "1",
            id: "291200680599",
            gmtCreate: "1608249275000",
            title: "忘了在衣柜里面还没穿呢  (模拟数据)",
            user: {
              userNick: "？**兮  (模拟数据)",
              userScore: null,
              userLogo:
                "https://wwc.alicdn.com/avatar/getAvatar.do?type=sns&width=64&height=64&userIdStr=vFQSMm-HPCHuvFQLOF9eX87HvC8WMFkGMk8yvmhzXHkT",
              userUrl:
                "https://h5.m.taobao.com/account/index.html?userId=vFQSMm-HPCHuvFQLOF9eX87HvC8WMFkGMk8yvmhzXHkT",
            },
          },
        ],
        similarFeeds: null,
        clusterName: null,
        feedType: "200",
        id: "292021847719",
        similarCount: null,
        targetUrl:
          "https://market.m.taobao.com/app/mtb/questions-and-answers/pages/detail/index.html?from=answer&spm=a3134.14087530&id=292021847719&clusterId=292021847719  (模拟数据)",
      },
      {
        gmtModified: "1606458155000",
        cardType: "1",
        clusterId: "284936413030",
        gmtCreate: "1604071328000",
        title: "会卡腋下吗？我肩膀比较宽，40cm，身高168，体重100斤  (模拟数据)",
        subFeedsCount: "31",
        subFeeds: [
          {
            gmtModified: null,
            elements: [],
            feedType: "151",
            answerType: "1",
            id: "285605319092",
            gmtCreate: "1604152960000",
            title: "会  (模拟数据)",
            user: {
              userNick: "栗**w  (模拟数据)",
              userScore: null,
              userLogo:
                "https://wwc.alicdn.com/avatar/getAvatar.do?type=sns&width=64&height=64&userIdStr=OmguMmPHv0RIOFIuXFRHvFNLv8xLMC84PGg4P0RzPmQT",
              userUrl:
                "https://h5.m.taobao.com/account/index.html?userId=OmguMmPHv0RIOFIuXFRHvFNLv8xLMC84PGg4P0RzPmQT",
            },
          },
        ],
        similarFeeds: null,
        clusterName: null,
        feedType: "200",
        id: "284936413030",
        similarCount: null,
        targetUrl:
          "https://market.m.taobao.com/app/mtb/questions-and-answers/pages/detail/index.html?from=answer&spm=a3134.14087530&id=284936413030&clusterId=284936413030  (模拟数据)",
      },
      {
        gmtModified: "1607416521000",
        cardType: "1",
        clusterId: "288555192736",
        gmtCreate: "1606517918000",
        title: "亲们薄厚怎样  (模拟数据)",
        subFeedsCount: "9",
        subFeeds: [
          {
            gmtModified: null,
            elements: [],
            feedType: "151",
            answerType: "1",
            id: "288618808769",
            gmtCreate: "1606558085000",
            title: "薄的  (模拟数据)",
            user: {
              userNick: "呆**火  (模拟数据)",
              userScore: null,
              userLogo:
                "https://wwc.alicdn.com/avatar/getAvatar.do?type=sns&width=64&height=64&userIdStr=XmQSM8*hMmQuMkRhMmQGvmHYMCxWM0gLX8gyPGkSPkxT",
              userUrl:
                "https://h5.m.taobao.com/account/index.html?userId=XmQSM8*hMmQuMkRhMmQGvmHYMCxWM0gLX8gyPGkSPkxT",
            },
          },
        ],
        similarFeeds: null,
        clusterName: null,
        feedType: "200",
        id: "288555192736",
        similarCount: null,
        targetUrl:
          "https://market.m.taobao.com/app/mtb/questions-and-answers/pages/detail/index.html?from=answer&spm=a3134.14087530&id=288555192736&clusterId=288555192736  (模拟数据)",
      },
      {
        gmtModified: "1607847723000",
        cardType: "1",
        clusterId: "289490611571",
        gmtCreate: "1606572052000",
        title: "买黑色的亲们，掉色吗？  (模拟数据)",
        subFeedsCount: "16",
        subFeeds: [
          {
            gmtModified: null,
            elements: [],
            feedType: "151",
            answerType: "1",
            id: "288731884006",
            gmtCreate: "1606637800000",
            title: "没穿  (模拟数据)",
            user: {
              userNick: "翟**6  (模拟数据)",
              userScore: null,
              userLogo:
                "https://wwc.alicdn.com/avatar/getAvatar.do?type=sns&width=64&height=64&userIdStr=OmlevGIWPCNYXHx4XF*zMC-eMCQbPGILPGHSXFQSMFIT",
              userUrl:
                "https://h5.m.taobao.com/account/index.html?userId=OmlevGIWPCNYXHx4XF*zMC-eMCQbPGILPGHSXFQSMFIT",
            },
          },
        ],
        similarFeeds: null,
        clusterName: null,
        feedType: "200",
        id: "289490611571",
        similarCount: null,
        targetUrl:
          "https://market.m.taobao.com/app/mtb/questions-and-answers/pages/detail/index.html?from=answer&spm=a3134.14087530&id=289490611571&clusterId=289490611571  (模拟数据)",
      },
      {
        gmtModified: "1603521148000",
        cardType: "1",
        clusterId: "283525704131",
        gmtCreate: "1603373418000",
        title: "亲爱的家人们，掉绒吗？  (模拟数据)",
        subFeedsCount: "5",
        subFeeds: [
          {
            gmtModified: null,
            elements: [],
            feedType: "151",
            answerType: "1",
            id: "283698004377",
            gmtCreate: "1603505821000",
            title: "没有买加绒的，不知道呢  (模拟数据)",
            user: {
              userNick: "明**5  (模拟数据)",
              userScore: null,
              userLogo:
                "https://wwc.alicdn.com/avatar/getAvatar.do?type=sns&width=64&height=64&userIdStr=v8gYPFQWMFZ-MCHGXmNuOH8GMF--OmQWPGZhXHcyMmgT",
              userUrl:
                "https://h5.m.taobao.com/account/index.html?userId=v8gYPFQWMFZ-MCHGXmNuOH8GMF--OmQWPGZhXHcyMmgT",
            },
          },
        ],
        similarFeeds: null,
        clusterName: null,
        feedType: "200",
        id: "283525704131",
        similarCount: null,
        targetUrl:
          "https://market.m.taobao.com/app/mtb/questions-and-answers/pages/detail/index.html?from=answer&spm=a3134.14087530&id=283525704131&clusterId=283525704131  (模拟数据)",
      },
    ]),
      [
        {
          gmtModified: "1611387784000",
          cardType: "1",
          clusterId: "293974829159",
          gmtCreate: "1609901285000",
          title: "会不会越洗越大 变形？黑色掉色吗",
          subFeedsCount: "5",
          subFeeds: [
            {
              gmtModified: null,
              elements: [],
              feedType: "151",
              answerType: "1",
              id: "294319254653",
              gmtCreate: "1609936735000",
              title: "是棉的，我买的是条纹的，没掉色",
              user: {
                userNick: "用**7",
                userScore: null,
                userLogo:
                  "https://wwc.alicdn.com/avatar/getAvatar.do?type=sns&width=64&height=64&userIdStr=XF*hXFRev8kuvm*IvmvSXFILPFMzOFgbP0gLPHkWMGcT",
                userUrl:
                  "https://h5.m.taobao.com/account/index.html?userId=XF*hXFRev8kuvm*IvmvSXFILPFMzOFgbP0gLPHkWMGcT",
              },
            },
          ],
          similarFeeds: null,
          clusterName: null,
          feedType: "200",
          id: "293974829159",
          similarCount: null,
          targetUrl:
            "https://market.m.taobao.com/app/mtb/questions-and-answers/pages/detail/index.html?from=answer&spm=a3134.14087530&id=293974829159&clusterId=293974829159",
        },
      ];
  },

  components: {
    InputView,
    Introduce,
  },
  methods: {
    // 点击底部分页器时
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSearchName(this.value);
    },
    //   子传父的方法
    getSearchName(value) {
      this.$store.commit("alterLoadContent", "正在获取问大家的相关数据");
      (this.goodsEntity = [
        {
          questionWords: "",
          questionCount: "",
          itemTitle: "",
          itemPic: "",
          buyerCount: "",
        },
      ]),
        (this.dataList = []);
      this.CanYouClick = false; //不允许再点击搜索
      this.value = value;
      getAskEveryone({
        itemid: this.value,
        page_num: this.currentPage,
      })
        .then((data) => {
          this.goodsEntity = [data.data.ask.item];
          let questionWords = "questionWords";
          this.goodsEntity[0][questionWords] = data.data.ask.questionWords;
          var newList = [];
          data.data.ask.list.forEach((element) => {
            newList.push(element[0]);
          });
          this.dataList = newList;
          this.total = Number(data.data.ask.total);
        })
        .finally(() => {
          this.$store.commit("alterJumpFlag", false);
          this.CanYouClick = true; //允许再次搜索
        });
    },
    //表格创建时间的接收参数
    dateFormat(row, column) {
      var date = Number(row.gmtCreate);
      if (date == undefined) {
        return "";
      }
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    //表格回答时间的接收参数
    answerFormat(row, column) {
      var date = Number(row.subFeeds[0].gmtCreate);
      if (date == undefined) {
        return "";
      }
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    downloadExcel() {
      this.$confirm("确定下载列表文件?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.excelData = this.dataList;
          this.export2Excel();
        })
        .catch(() => {});
    },
    export2Excel() {
      var that = this;
      require.ensure([], () => {
        const { export_json_to_excel } = require("@/excel/export2Excel"); // 这里必须使用绝对路径，使用@/+存放export2Excel的路径
        // 导出的表头名信息
        const tHeader = [
          "创建时间",
          "买家昵称",
          "问题",
          "回答时间",
          "回答",
          "链接",
        ];
        // 导出的表头字段名，需要导出表格字段名
        const filterVal = [
          "gmtCreate",
          "subFeeds[0].user.userNick",
          "title",
          "subFeeds[0].gmtCreate",
          "subFeeds[0].title",
          "targetUrl",
        ];

        let vue = this;
        const list = that.excelData;
        let data = list.map(function (item) {
          return filterVal.map(function (path) {
            let newVal = _.get(item, path);
            if (path == "gmtCreate" || path == "subFeeds[0].gmtCreate") {
              if (newVal) {
                newVal = moment(Number(newVal)).format("YYYY-MM-DD HH:mm:ss");
              }
            }
            return newVal;
          });
        });
        let curDate = moment().format("YYYY-MM-DD");
        export_json_to_excel(tHeader, data, "问大家-" + curDate); // 导出的表格名称，根据需要自己命名
      });
    },
  },
};
</script>

<style lang="less" scoped>
.bannerContent {
  display: flex;
}
.img_floor {
  width: 100px;
  height: 100px;
  background: linear-gradient(0deg, #ceefff, #7bd5ff);
  margin-right: 50px;
}

.target-link {
  color: #1b84ff;
}

.control-tool {
  padding: 4px;
  text-align: right;
}
</style>