var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"banner"},[_c('warning-tip',{attrs:{"code":"SPTSSPWD"}}),_c('main-top',{attrs:{"title":"问大家","desc":"在线查询天猫、淘宝商品宝贝问大家","spend":"SPTSSPWD"}},[_c('InputView',{attrs:{"extractProductId":true,"CanYouClick":_vm.CanYouClick,"placeholder-name":"请输入您的宝贝链接或宝贝ID"},on:{"sendSearchName":_vm.getSearchName}})],1),_c('bordure-row',{staticStyle:{"margin-bottom":"30px"},attrs:{"title":"商品信息","shadow":""}},[_c('div',{staticClass:"bannerContent",staticStyle:{"padding-bottom":"20px"}},[(_vm.goodsEntity[0].itemPic == '')?_c('div',{staticClass:"img_floor"}):_c('img',{staticStyle:{"dispaly":"block","width":"100px","height":"100px"},attrs:{"src":_vm.goodsEntity[0].itemPic,"alt":"图片加载中"}}),_c('div',{staticStyle:{"margin-left":"16px"}},[_c('p',{staticStyle:{"font-size":"14px","color":"#333333","letter-spacing":"0","margin":"6px 0"}},[_vm._v(" 商品标题："+_vm._s(_vm.goodsEntity[0].itemTitle)+" ")]),_c('p',{staticStyle:{"font-size":"12px","color":"#666666","letter-spacing":"0","margin-bottom":"16px"}},[_vm._v(" 问答关键字："+_vm._s(_vm.goodsEntity[0].questionWords)+" ")]),_c('p',{staticStyle:{"font-size":"14px","color":"#333333","letter-spacing":"0"}},[_vm._v(" 购买人数："+_vm._s(_vm.goodsEntity[0].buyerCount)+" ")])])]),_c('div',[_c('div',{staticClass:"control-tool"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.downloadExcel}},[_vm._v("一键导出")])],1),_c('el-table',{staticStyle:{"width":"100%","border":"1px solid #f4f4f4"},attrs:{"data":_vm.dataList,"size":"mini","header-cell-style":{
            background: '#f8f8f8',
            'font-family': 'PingFangSC-Regular',
            color: '#666666 !important',
            'letter-spacing': '0',
            'text-align': 'center',
            'font-weight': 'normal',
          },"row-key":"clusterId"}},[_c('el-table-column',{attrs:{"align":"center","prop":"gmtCreate","label":"创建时间","sortable":"","min-width":"100","formatter":_vm.dateFormat}}),_c('el-table-column',{attrs:{"align":"center","prop":"subFeeds[0].user.userNick","label":"买家昵称","min-width":"100"}}),_c('el-table-column',{attrs:{"prop":"title","label":"问题","width":"220","align":"center"}}),_c('el-table-column',{attrs:{"prop":"subFeeds[0].gmtCreate","label":"回答时间","formatter":_vm.answerFormat,"min-width":"100","align":"center"}}),_c('el-table-column',{attrs:{"prop":"subFeeds[0].title","label":"回答","width":"220","align":"center"}}),_c('el-table-column',{attrs:{"prop":"targetUrl","align":"center","label":"链接","min-width":"240"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('a',{staticClass:"target-link",attrs:{"href":scope.row.targetUrl,"target":"_blank"}},[_vm._v(_vm._s(scope.row.targetUrl))])]}}])})],1),_c('el-pagination',{staticClass:"pagination",attrs:{"current-page":_vm.currentPage,"page-size":10,"layout":"total, prev, pager, next,jumper","total":_vm.total},on:{"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})],1)]),_c('Introduce',{attrs:{"introduce":_vm.introduce}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }